.bar-type-item___button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: left;
  margin: 0;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 3px;
}
.bar-type-item___content {
  margin-left: 1.2rem;
}
.bar-type-item___button:hover,
.bar-type-item___button.active {
  background: #f9fafb;
}

.bar-type-item___button strong {
  display: block;
  font-size: 1.4rem;
  color: #006fbb;
}
.bar-type-item___button:hover strong,
.bar-type-item___button.active strong {
  color: #084e8a;
}
.bar-type-item___button:hover .bar-type-item___icon,
.bar-type-item___button.active .bar-type-item___icon {
  background-color: #dfe3e8;
}
.bar-type-item___subtitle {
  color: var(--p-text-subdued, #637381);
  margin-top: 3px;
  display: block;
}

.bar-type-item___icon {
  border-radius: 3px;
  background-color: #f4f6f8;
  padding: 1.2rem;
}
.bar-type___plan {
  background: rgb(92, 106, 196);
  padding: 3px 5px;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-size: 9px;
  margin-left: 7px;
  position: relative;
  top: -3px;
}
.bar-type___plan-free {
  background: #dfe3e8;
  color: #919eab;
}
